import { useIsDesktop } from '@root/infra/user-agent';
import { VerificationReminder } from '@root/shared-files/modules/auth/containers';
import { FeedbackModal } from '@root/modules/user-settings/containers/feedback-modal';
import { FC, PropsWithChildren } from 'react';
import { LayoutElements } from '../components';
import { Header } from './header';
import { MobileHeader } from './mobile-header';
import { Sidebar } from './sidebar';
import { useSelector } from 'react-redux';
import { userSettingsSelector } from '@root/modules/user-settings/store/user-settings.selector';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';

interface Props {
  extraHeaderHeight?: number;
  extraMenuWidth?: number;
  hideSocial?: boolean;
}

export const Layout: FC<PropsWithChildren<Props>> = ({ children, extraHeaderHeight, extraMenuWidth }) => {
  const isDesktop = useIsDesktop();
  const menuOpened = useSelector(userSettingsSelector.menuOpened);

  const { isGhostLogin } = useGhostLogin();

  return (
    <LayoutElements.SafeArea extraHeaderHeight={extraHeaderHeight} extraMenuWidth={extraMenuWidth} isDesktop={isDesktop} menuOpened={menuOpened} isGhostLogin={isGhostLogin}>
      {isDesktop && <Sidebar />}
      {isDesktop ? <Header /> : <MobileHeader />}
      <LayoutElements.Content>{children}</LayoutElements.Content>
      <FeedbackModal />
      <VerificationReminder />
    </LayoutElements.SafeArea>
  );
};
