import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { authSelector } from '@root/shared-files/modules/auth/store';
import { SubscriptionProductType } from '@root/modules/subscriptions/types/subscription-product-type';
import { CrownIcon } from '@root/shared/icons/crown-icon';
import { GiftIcon } from '@root/shared/icons/gift-icon';
import { useGetCurrentSubscription } from './use-get-current-subscription';

export const useSubscriptionTitles = () => {
  const { t } = useTranslation('common');
  const user = useSelector(authSelector.user);

  const { currentSubscription, subscriptions } = useGetCurrentSubscription();

  const title = useMemo(() => user?.fullName as string, [user]);

  const { subTitle, icon, className } = useMemo(() => {
    if (!subscriptions) {
      return {
        subTitle: null,
        icon: null,
        className: '',
      };
    }

    if (currentSubscription) {
      if (currentSubscription.type === SubscriptionProductType.Pro) {
        return {
          subTitle: t('userInfo.pro'),
          icon: CrownIcon,
          className: 'text-purple-300 font-medium',
        };
      }
      if (currentSubscription.type === SubscriptionProductType.Advanced) {
        return {
          subTitle: t('userInfo.advance'),
          icon: CrownIcon,
          className: 'text-success-400 font-medium',
        };
      }
      if (currentSubscription.type === SubscriptionProductType.Basic) {
        return {
          subTitle: t('userInfo.basic'),
          icon: CrownIcon,
          className: 'text-primary-400 font-medium',
        };
      }
      if (currentSubscription.type === SubscriptionProductType.Trial) {
        return {
          subTitle: t('userInfo.trial'),
          icon: GiftIcon,
          className: 'text-primary-100 font-medium',
        };
      }
    }

    return {
      subTitle: t('userInfo.starter'),
      icon: null,
      className: 'text-grayscale-500',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptions, currentSubscription]);

  return {
    title,
    subTitle,
    icon,
    className,
  };
};
