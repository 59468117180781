import { UserSubscriptionInfo } from '@root/shared-files/modules/subscriptions/types/user-subscription-info';

import { IAuthUser } from '../types';

export class AuthUserMapper {
  public static toDomain(item): IAuthUser {
    return {
      id: item.id,
      fullName: item.fullName || item.full_name,
      email: item.email,
      emailVerifiedAt: item.emailVerifiedAt || item.email_verified_at,
      forexWizardCompletedAt: item.forexWizardCompletedAt,
      country: item.country,
    };
  }

  public static collectSubscriptionInfo(item): UserSubscriptionInfo {
    return {
      sub: '',
      email: item.user.email,
      emailVerifiedAt: item.user.emailVerifiedAt,
      roles: item.roles,
      permissions: item.permissions,
    };
  }
}
