import { Modal } from '@root/shared/ui/modal';
import { FC, useCallback } from 'react';
import { ChangePasswordForm, useChangePasswordForm } from '../hooks/use-change-password-form';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { PasswordField } from '@root/shared/form';
import { Button } from '@root/shared/ui/button';
import { BackupCodeContent } from './two-fa/backup-code-content';
import { TwoFAAuthType } from '../types/two-fa';
import { EnterCodeContent } from './two-fa/enter-code-content';
import { sendCode } from '../services/two-fa/send-code.service';
import { notify } from '@root/shared/utils/notification';
import { Image } from '@root/shared/ui/image';
import { Title } from '@root/shared/ui/typography';
import { useGetTwoFaMethods } from '../hooks/use-get-two-fa-methods';
import { getActiveMethod } from '../helpers/get-active-method';

import globalPasswordImage from '@root/assets/images/global-password.webp';
import { useDispatch } from 'react-redux';
import { authSlice } from '../store';

const ChangePasswordFormComponent: FC<FormikProps<ChangePasswordForm>> = ({ errors, values, status, isSubmitting, submitForm, handleSubmit, setFieldValue }) => {
    const { t } = useTranslation('settings');
    const { data: methods } = useGetTwoFaMethods();
    const { activeMethods, email: activeMethodEmail } = getActiveMethod(methods || []);

    const dispatch = useDispatch();
    const onCancel = useCallback(() => dispatch(authSlice.actions.setChangePasswordModalOpen(false)), []);

    const handleUpdateClick = useCallback(async () => {
        if (!values.type) {
            await submitForm();
        } else {
            if (values.type === TwoFAAuthType.Email) {
                const response = await sendCode();
                if (response.status !== 200) {
                    notify({
                        type: 'danger',
                        title: response.payload,
                    })
                }
            }
            setFieldValue('step', 2);
        }
    }, [values, submitForm, setFieldValue]);

    const isDisabled = !!errors.password || !!errors.oldPassword || !!errors.confirmPassword || !values.password || !values.oldPassword || !values.confirmPassword;

    if (status?.type === 'success') {
        return (
            <Modal isOpen footer={null} onCancel={onCancel} disabledOutSideClick>
                <div className='w-full flex flex-col items-center'>
                    <Image quality={100} height={94} width={127} src={globalPasswordImage} wrapperClassName='mb-6' alt='E-Email sent illustration' />
                    <Title className='text-center mb-8' level={5} bold>
                        {t('changePasswordModal.success')}
                    </Title>
                    <Button outlined onClick={onCancel}>
                        {t('changePasswordModal.ok')}
                    </Button>
                </div>
            </Modal>
        );
    }

    return <Modal isOpen title={t('changePasswordModal.title')} footer={null} onCancel={onCancel} disabledOutSideClick>
        <form onSubmit={handleSubmit}>
            {values.step === 1 && <div className='flex flex-col justify-start gap-4'>
                <PasswordField
                    name='oldPassword'
                    label={t('changePasswordModal.oldPassword.label')}
                    placeholder={t('changePasswordModal.oldPassword.placeholder')}
                />
                <PasswordField
                    name='password'
                    label={t('changePasswordModal.password.label')}
                    placeholder={t('changePasswordModal.password.placeholder')}
                />
                <PasswordField
                    name='confirmPassword'
                    label={t('changePasswordModal.confirmPassword.label')}
                    placeholder={t('changePasswordModal.confirmPassword.placeholder')}
                />
                <div className='w-full flex justify-between items-center gap-4'>
                    <Button outlined onClick={onCancel}>{t('changePasswordModal.cancel')}</Button>
                    <Button
                        type='button'
                        loading={isSubmitting}
                        onClick={handleUpdateClick}
                        disabled={isDisabled}
                    >
                        {t('changePasswordModal.updatePassword')}
                    </Button>
                </div>
            </div>}
            {values.step === 2 && <div>
                {values.useBackupCode ? <BackupCodeContent type={values.type as TwoFAAuthType} /> : <div>
                    <EnterCodeContent
                        type={values.type as TwoFAAuthType}
                        email={activeMethodEmail}
                        hasAnotherMethod={activeMethods?.length > 1}
                        formName='ChangePasswordForm'
                    />
                    <div className='w-full flex justify-between items-center gap-4 mt-4'>
                        <Button outlined onClick={() => setFieldValue('step', 1)}>{t('changePasswordModal.cancel')}</Button>
                        <Button loading={isSubmitting} type='submit'>{t('changePasswordModal.confirm')}</Button>
                    </div>
                </div>}
            </div>}
        </form>
    </Modal>
};

export const ChangePasswordModal: FC = () => {
    const { initialValues, schema, onSubmit } = useChangePasswordForm();

    return <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
        component={ChangePasswordFormComponent}
    />
};


