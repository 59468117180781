
// common
export const SHOULD_REFRESH_TOKEN = 'shouldRefreshToken';
export const SHOULD_REFETCH_PROVIDERS = 'shouldRefetchProviders';
export const IS_UPGRADED_SUBSCRIPTION = 'isUpgradedSubscription';
export const IS_PARER_TRADING_ON = 'isPaperTradingOn';
export const GHOST_LOGIN_TOKEN = 'ghostLoginToken';
export const GHOST_LOGIN_SESSION_START = 'ghostLoginSessionStart';
export const GHOST_SESSION_PAGE_LEAVE_TIME = 'ghostSessionPageLeaveTime';
export const SUBSCRIPTION_PURCHASE_URL = 'subscriptionPurchaseUrl';
export const ENABLE_TWO_FA_MODAL_SHOWN = 'enableTwoFaModalShown';
export const KUCOIN_CHANGE_MODAL_SHOWN = 'kucoinChangeModalShown';
export const PRESETS_SUBSCRIPTION_ID_KEY = 'presetsSubscriptionId';
export const PRESETS_REDIRECT_TO_ASSIST_CREATION = 'redirectToAssistCreation';

// forex
export const PREFERRED_BROKER_KEY = 'preferredBroker';
export const PREFERRED_MT_TYPE = 'preferredMtType';
export const PAGINATION_PREFERENCES_KEY = 'paginationPreferences';
export const FOREX_WIZARD_SKIPPED = 'forexWizardSkipped';
export const PROVIDER_TO_BE_FETCHED = 'providerToBeFetched';
export const HISTORY_SYNCRONIZED = 'history-synchronized';

// crypto
export const USER_EXCHANGES = 'user-exchanges';
export const GRID_STRATEGY_MODAL_SHOWN_KEY = 'grid-strategy-modal-shown';
export const DCA_CANDLES_INTERVAL = 'dca-candles-interval';
export const DCA_HISTORICAL_PERIOD = 'dca_historical_period';
